const global = (typeof window !== "undefined") ? window : (0, eval)("this");

import jquery from 'jquery';
global.$ = window.$ = global.jQuery = window.jQuery = jquery;

import * as Cookies from "js-cookie";
global.Cookies = window.Cookies = Cookies;

import * as bootstrap from "bootstrap"
global.bootstrap = window.bootstrap = bootstrap;
import "./assets/scss/bootstrap.scss"

import "./assets/fontawesome/css/all.min.css"

import "./assets/scss/custom.scss"
import "./app.scss"
